import React from "react";
import { Copyright } from "../../graphql/queries/_common.js";
import {
    copyrightItem,
    copyrightsContainer,
} from "./MediaItemCopyrights.css.js";

interface Props {
    copyrights: Copyright[];
}
const MediaItemCopyrights: React.FC<Props> = ({ copyrights }) => {
    return (
        <div className={copyrightsContainer}>
            <span>©</span>
            {copyrights.map(i => (
                <a href={i.url} className={copyrightItem} key={i.author}>
                    {i.author}
                </a>
            ))}
        </div>
    );
};

export default MediaItemCopyrights;
