import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import Intro from "./Intro.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import Spinner from "../Spinner.js";
import { GetProductData, GET_PRODUCT } from "../../graphql/queries/products.js";
import MetaTags from "../MetaTags.js";
// import HtmlGoogleStructuredData from "../helmet/HtmlGoogleStructuredData";
import ActionsTrigger from "../layout/ActionsTrigger.js";
import {
    makeBackgroundImgUrl,
    makeOpenGraphImage,
} from "../../helpers/utils.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import { App } from "../../graphql/queries/app.js";
import { Image } from "../../graphql/queries/gallery.js";
import { container } from "../styles/common.css.js";
import { productContent } from "./Product.css.js";

const Product: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    const { data, loading, error } = useQuery<GetProductData>(GET_PRODUCT, {
        variables: {
            id: productId,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg error={error} />;
    }
    if (data && data.product) {
        const path = `/apps/${productId}`;

        const { product } = data;
        const { content } = product;
        // const operatingSystem = Array.from(
        //     new Set(product.apps.map(app => app.platform)).values(),
        // ).join(",");

        return (
            <>
                <MetaTags
                    path={path}
                    title={content.metaTitle}
                    description={content.metaDescription || ""}
                    openGraphImage={makeOpenGraphImage(product.image)}
                />
                {/* <HtmlGoogleStructuredData
                    data={{
                        type: "App",
                        title: content.introTitle,
                        operatingSystem: operatingSystem,
                        ratingValue: product.ratingValue,
                        ratingCount: product.reviewCount,
                        bestRating: product.bestRating,
                    }}
                /> */}
                <Intro
                    backgroundImage={makeBackgroundImgUrl(
                        product.introBackgroundImage,
                    )}
                    title={content.title}
                    subtitle={content.subtitle}
                    link={content.introLink}
                    icon={product.icon}
                    productId={product.id}
                />
                <div className={container.flex800}>
                    <MarkdownRenderer
                        className={productContent}
                        source={product.content.text}
                        apps={product.apps.filter((a): a is App => !!a)}
                        productId={product.id}
                        images={product.textImages?.filter(
                            (i): i is Image => !!i,
                        )}
                        reviews={product.reviews}
                        awards={product.awards}
                    />
                </div>
                <ActionsTrigger position="50vh" />
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default Product;
