import gql from "graphql-tag";

import { image, Image } from "./gallery.js";
import { FeedConnection, LangCode } from "./_common.js";
import { App, AppIcon } from "./app.js";

export interface GetProductVariables {
    id: string;
}
export interface Product<C> {
    id: string;
    ratingValue: string;
    reviewCount: string;
    bestRating: string;
    featured: boolean;
    image: Image | null;
    textImages: (Image | null)[] | null;
    introBackgroundImage: Image | null;
    contentLangs: LangCode[];
    content: C;
    apps: (App | null)[];
    icon: AppIcon | null;
    reviews: Review[] | null;
    awards: ProductAward[] | null;
}

export interface ProductContent {
    langCode: LangCode;
    title: string;
    subtitle: string;
    metaTitle: string;
    metaDescription: string | null;
    text: string;
    introLink: Link | null;
    faq: Faq[] | null;
}

export interface ProductAward {
    url: string | null;
    imgUrl: string;
    alt: string;
}
export interface Faq {
    question: string;
    answer: string;
}
export interface Link {
    url: string;
    text: string;
}
export interface Review {
    id: string;
    quote: string;
    author: string;
    url: string | null;
}

export interface GetProductData {
    product: Product<ProductContent> | null;
}

export const GET_PRODUCT = gql`
    query getProduct($id: ProductID!) {
        product(id: $id) {
            id
            ratingValue
            reviewCount
            bestRating
            featured
            icon {
                id
                sizes
                sourceUrl
            }
            introBackgroundImage {
                ...image
            }
            textImages {
                ...image
            }
            image {
                ...image
            }
            apps {
                id
                title
                icon {
                    sourceUrl
                    sizes
                }
                marketUrl
                marketId
                platform
            }
            content {
                title
                subtitle
                metaTitle
                metaDescription
                text
                introLink {
                    url
                    text
                }
            }
            reviews {
                id
                quote
                author
                url
            }
            awards {
                imgUrl
                alt
                url
            }
        }
    }
    ${image}
`;

type ProductContentForFeed = Pick<ProductContent, "title" | "metaDescription">;

export type ProductsForFeed = Pick<
    Product<ProductContentForFeed>,
    "id" | "content" | "featured" | "image"
>;
export interface GetProductsFeedData {
    products: FeedConnection<ProductsForFeed> | null;
}
export const GET_PRODUCTS = gql`
    query getProducts {
        products {
            totalCount
            offset
            edges {
                node {
                    id
                    featured
                    image {
                        ...image
                    }
                    content {
                        title
                        metaDescription
                    }
                }
            }
        }
    }
    ${image}
`;

type ProductContentForSupportPage = Pick<ProductContent, "title" | "faq">;
type ProductItemForSupportPage = Pick<
    Product<ProductContentForSupportPage>,
    "id" | "content" | "introBackgroundImage" | "image"
>;
export interface GetProductForSupportPageData {
    product: ProductItemForSupportPage | null;
}

export const GET_PRODUCT_FOR_SUPPORT_PAGE = gql`
    query getProductForSupportPage($id: ProductID!) {
        product(id: $id) {
            id
            introBackgroundImage {
                ...image
            }
            image {
                ...image
            }
            content {
                title
                faq {
                    question
                    answer
                }
            }
        }
    }
    ${image}
`;

type ProductsForSupportPage = Pick<
    Product<ProductContentForSupportPage>,
    "id" | "content" | "icon"
>;
export interface GetProductsForSupportPageData {
    products: FeedConnection<ProductsForSupportPage> | null;
}
export const GET_PRODUCTS_FOR_SUPPORT_PAGE = gql`
    query getProductsForSupportPage {
        products {
            edges {
                node {
                    id
                    content {
                        title
                    }
                    icon {
                        sourceUrl
                    }
                }
            }
        }
    }
`;
