import { Root } from "mdast";

const htmlCommentsRegex = /<!--([\s\S]*?)-->/g;

export default function markdownHandling() {
    return (tree: Root) => {
        for (let i = 0; i < tree.children.length; i++) {
            const node = tree.children[i];
            if (node.type === "html" && node.value.match(htmlCommentsRegex)) {
                if (node.value.includes("CONTAINER:")) {
                    const match = node.value.match(/CONTAINER:([^\s]+)/);

                    if (match) {
                        tree.children.splice(i + 1, 0, {
                            type: "inlineCode",
                            value: match[1],
                        });
                    }
                    tree.children.splice(i, 1);
                    i--;
                }
            } else if (node.type === "blockquote") {
                if (
                    node.children.length === 1 &&
                    node.children[0].type === "paragraph"
                ) {
                    const p = node.children[0];
                    if (p.children.length === 1) {
                        const c = p.children[0];
                        if (c.type === "image") {
                            tree.children[i] = {
                                type: "inlineCode",
                                value: `image-with-caption:${c.url}`,
                            };
                        }
                    }
                }
            }
        }
    };
}
