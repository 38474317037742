import React from "react";

import { ANGLE_LEFT, ANGLE_RIGHT, QUOTE } from "../../config/icons.js";
import { Review } from "../../graphql/queries/products.js";
import Icon from "../svg/Icon.js";
import {
    btnIcon,
    commonLeftButton,
    commonRightButton,
    dot,
    dots,
} from "./commonSlider.css.js";
import Slide from "./Slide.js";
import {
    authorLink,
    authorName,
    list,
    listWrapper,
    quoteContainer,
    quoteIcon,
    quoteText,
    sliderContainer,
    listContainer,
} from "./ReviewsSlider.css.js";

interface Props {
    slides: Review[];
}
const getStaticIndex = (loopIndex: number, size: number) => {
    let rest = loopIndex % size;
    if (rest < 0) {
        return (rest += size);
    }
    return rest;
};
const ReviewsSlider: React.FC<Props> = ({ slides }) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const size = slides.length;

    const nextSlide = () => {
        if (size > 1) setActiveIndex(activeIndex + 1);
    };
    const prevSlide = () => {
        if (size > 1) setActiveIndex(activeIndex - 1);
    };
    return (
        <div>
            <div className={`container ${sliderContainer}`}>
                <div className={listContainer}>
                    <div
                        className={listWrapper}
                        style={{
                            transform: `translateX(${
                                -100 * size - 100 * activeIndex
                            }%)`,
                        }}
                    >
                        <div
                            className={list}
                            style={{
                                transform: `translateX(${100 * activeIndex}%)`,
                            }}
                        >
                            {Array(size * 2 + 1)
                                .fill(1)
                                .map((_, index) => {
                                    const staticIndex = getStaticIndex(
                                        activeIndex + index,
                                        size,
                                    );
                                    const { url, author, quote } =
                                        slides[staticIndex];
                                    return (
                                        <Slide
                                            key={`${index}`}
                                            onRightHandler={nextSlide}
                                            onLeftHandler={prevSlide}
                                            isActive={activeIndex === index}
                                            isReviewSlide
                                        >
                                            <div className={quoteContainer}>
                                                <Icon
                                                    path={QUOTE}
                                                    className={quoteIcon}
                                                />
                                                <p className={quoteText}>
                                                    {quote}
                                                </p>
                                            </div>
                                            {url ? (
                                                <p className={authorName}>
                                                    <a
                                                        className={authorLink}
                                                        href={url}
                                                    >
                                                        {author}
                                                    </a>
                                                </p>
                                            ) : (
                                                <p className={authorName}>
                                                    {author}
                                                </p>
                                            )}
                                        </Slide>
                                    );
                                })}
                        </div>
                    </div>
                </div>

                <div
                    className={commonLeftButton}
                    onClick={() => prevSlide()}
                    hidden={size <= 2}
                >
                    <Icon path={ANGLE_LEFT} className={btnIcon} />
                </div>
                <div
                    className={commonRightButton}
                    onClick={() => nextSlide()}
                    hidden={size <= 2}
                >
                    <Icon path={ANGLE_RIGHT} className={btnIcon} />
                </div>
            </div>
            <div className={dots}>
                {slides.map(({ author }, index) => {
                    const activeDot =
                        activeIndex - size * Math.floor(activeIndex / size);
                    return (
                        <span
                            className={dot}
                            key={`${author}${index}`}
                            style={{
                                backgroundColor:
                                    index === activeDot ? "#000" : "#D9D9D9",
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ReviewsSlider;
