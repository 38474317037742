import React from "react";
import MetaTags from "../MetaTags.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import Spinner from "../Spinner.js";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { GetProductData, GET_PRODUCT } from "../../graphql/queries/products.js";
import {
    makeBackgroundImgUrl,
    makeOpenGraphImage,
} from "../../helpers/utils.js";
// import ActionsTrigger from "../layout/ActionsTrigger.js";
import Intro from "./Intro.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import { container } from "../styles/common.css.js";
import { App } from "../../graphql/queries/app.js";
import { Image } from "../../graphql/queries/gallery.js";
import { productContent } from "../product/Product.css.js";
// import StoreButtons from "../product/StoreButtons.js";

const SolarWalkLiteWithSolarTrail: React.FC = () => {
    const productId = "solar-walk-lite";
    const { data, loading, error } = useQuery<GetProductData>(GET_PRODUCT, {
        variables: {
            id: productId,
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.product) {
        const path = `/apps/${productId}`;

        const { product } = data;
        const { content, reviews, awards, apps, textImages } = product;
        return (
            <>
                <MetaTags
                    path={path}
                    title={content.metaTitle}
                    description={content.metaDescription || ""}
                    openGraphImage={makeOpenGraphImage(product.image)}
                />
                <Intro
                    backgroundImage={makeBackgroundImgUrl(
                        product.introBackgroundImage,
                    )}
                    title={content.title}
                    subtitle={content.subtitle}
                    link={{
                        text: "Download",
                        url: "https://solarwalklite.page.link/NTST",
                    }}
                    icon={product.icon}
                    productId={product.id}
                />
                <div className={container.flex800}>
                    <MarkdownRenderer
                        className={productContent}
                        source={content.text}
                        apps={apps.filter((a): a is App => !!a)}
                        productId={product.id}
                        images={textImages?.filter((i): i is Image => !!i)}
                        reviews={reviews}
                        awards={awards}
                    />
                </div>
                {/* <ActionsTrigger position="200vh" /> */}
            </>
        );
    }

    return <NotFoundErrorMsg />;
};

export default SolarWalkLiteWithSolarTrail;
