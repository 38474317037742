import React, { useContext } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Link, useLocation } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import rehypeAttrs from "rehype-attr";

import ConfigContext from "../../config/config-context.js";
import {
    gaConversionLinkClick,
    gaMarkdownLinkClick,
} from "../../helpers/googleAnalytics.js";
import { markdownDefaultStyle } from "./MarkdownRenderer.css.js";
import ImageItem from "./ImageItem.js";
import { Image } from "../../graphql/queries/gallery.js";
import StoreButtons from "../product/StoreButtons.js";
import { App } from "../../graphql/queries/app.js";
import markdownHandlingPlugin from "../../helpers/markdownHandlingPlugin.js";
import { ProductAward, Review } from "../../graphql/queries/products.js";
import Awards from "../product/Awards.js";
import ImageSlider, { ImageSlideContent } from "../slider/ImageSlider.js";
import ReviewsSlider from "../slider/ReviewsSlider.js";
import ImageWithCaption from "./ImageWithCaption.js";

interface MarkdownLinkProps {
    href?: string;
    children?: React.ReactNode;
}

const MarkdownLink: React.FC<MarkdownLinkProps> = ({ href, children }) => {
    const _href: string = href || "";
    const { conversionDomains } = useContext(ConfigContext);
    const { pathname } = useLocation();
    if (_href.includes("#")) {
        return (
            <a
                href={_href}
                onClick={() => {
                    gaMarkdownLinkClick("hash", _href, pathname);
                }}
            >
                {children}
            </a>
        );
    }

    let path = "";
    try {
        const { hostname, pathname } = new URL(_href);
        if (hostname !== "vitotechnology.com") {
            return (
                <a
                    href={_href}
                    onClick={() => {
                        conversionDomains.includes(hostname)
                            ? gaConversionLinkClick(
                                  "in_article_conversion_link",
                                  pathname,
                                  _href,
                              )
                            : gaMarkdownLinkClick("external", _href, pathname);
                    }}
                >
                    {children}
                </a>
            );
        }
        path = pathname;
    } catch {
        path = _href;
    }
    const to = path;

    // TODO: check links with search params from markdown source
    return (
        <Link
            to={to}
            onClick={() => {
                gaMarkdownLinkClick("internal", to, pathname);
            }}
        >
            {children}
        </Link>
    );
};

interface Props {
    source: string | null;
    className?: string;
    images?: Image[] | null;
    apps?: App[];
    productId?: string;
    reviews?: Review[] | null;
    awards?: ProductAward[] | null;
}

const makeImg =
    (images?: Image[] | null): React.FC<{ src?: string }> =>
    ({ src }) => {
        const image = images?.find(i => i.id === src);
        if (!image) return null;
        return (
            <ImageItem
                noAspectWrapper
                image={image}
                srcSetSizes={{
                    laptop: 968,
                    tablet: 736,
                }}
            />
        );
    };

const MarkdownRenderer: React.FC<Props> = ({
    source,
    className,
    images,
    apps,
    productId,
    reviews,
    awards,
}) => {
    const classNames = [markdownDefaultStyle];
    if (className) classNames.push(className);

    const List: React.FC<{ children?: any; className?: string }> = ({
        children,
        className,
    }) => {
        if (className === "slider-list" && children && children.length) {
            const slides: ImageSlideContent[] = [];
            for (const li of children) {
                if (li.type !== "li") continue;
                const liChildren = li.props.children;
                const slide: Partial<ImageSlideContent> = {};
                // TODO: next code must be improved
                for (let liChild of liChildren) {
                    if (liChild.type === "p") {
                        const pChildren = liChild.props?.children;
                        const liImgSrc = pChildren?.props?.src;
                        if (typeof pChildren === "string") {
                            slide.description = pChildren;
                        }
                        if (liImgSrc) {
                            slide.image =
                                images?.find(img => img.id === liImgSrc) ||
                                undefined;
                            slide.id = slide.image?.id;
                        }
                    }
                }

                if (slide.id && slide.image && slide.description) {
                    slides.push(slide as ImageSlideContent);
                }
            }
            return slides.length > 0 ? (
                <ImageSlider
                    slides={slides}
                    delaySec={3}
                    srcSetSizes={{ desktop: 770, laptop: 770, tablet: 538 }}
                />
            ) : null;
        }
        return <ul className={className}>{children}</ul>;
    };

    const Code: React.FC<{ children?: any }> = ({ children }) => {
        if (children && typeof children === "string") {
            if (children.includes("APP_VERSIONS") && apps && productId) {
                return <StoreButtons apps={apps} productId={productId} />;
            }
            if (children.includes("REVIEWS") && reviews) {
                return reviews.length > 0 ? (
                    <ReviewsSlider slides={reviews} />
                ) : null;
            }
            if (children.includes("AWARDS") && awards) {
                return <Awards awards={awards} />;
            }
            if (images && children.startsWith("image-with-caption:")) {
                const imageId = children.split(":")[1];
                const image = images.find(i => i.id === imageId);
                if (!image) return null;
                return (
                    <ImageWithCaption
                        image={image}
                        srcSetSizes={{
                            laptop: 968,
                            tablet: 736,
                        }}
                    />
                );
            }
        }
        return <code>{children}</code>;
    };

    return (
        <Markdown
            className={classNames.join(" ")}
            children={source}
            skipHtml={true}
            components={{
                link: MarkdownLink,
                img: makeImg(images),
                code: Code,
                ul: List,
            }}
            remarkPlugins={[remarkGfm, [markdownHandlingPlugin]]}
            rehypePlugins={[[rehypeRaw], [rehypeAttrs, { properties: "attr" }]]}
        />
    );
};
export default MarkdownRenderer;
