import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { OPEN_GRAPH_IMAGE_PATH } from "../../config/content.js";
import {
    GetProductsForSupportPageData,
    GET_PRODUCTS_FOR_SUPPORT_PAGE,
} from "../../graphql/queries/products.js";
import Content from "../../i18n/content.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import {
    commonPageDescription,
    commonPageTitle,
    container,
} from "../styles/common.css.js";
import {
    listItem,
    listItemImg,
    listItemLink,
    productList,
    subtitleStyle,
} from "./Support.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    path: string;
}
const Support: React.FC<Props> = ({ path }) => {
    const {
        supportPage: {
            title,
            subtitle,
            description,
            metaTitle,
            metaDescription,
        },
    } = useContext(Content);

    const { data, loading, error } = useQuery<GetProductsForSupportPageData>(
        GET_PRODUCTS_FOR_SUPPORT_PAGE,
    );

    if (loading) {
        return <Spinner />;
    }
    if (error) {
        return <ErrorMsg error={error} />;
    }
    if (data && data.products) {
        return (
            <>
                <MetaTags
                    path={path}
                    title={metaTitle || title}
                    description={metaDescription || description}
                    openGraphImage={[OPEN_GRAPH_IMAGE_PATH, "1200x630"]}
                />

                <div className={container.flex800}>
                    <h1 className={commonPageTitle}>{title}</h1>
                    <p className={commonPageDescription}>{description}</p>
                    <p className={subtitleStyle}>{subtitle}</p>

                    <ul className={productList}>
                        {data.products.edges.map(
                            ({
                                node: {
                                    id,
                                    icon,
                                    content: { title },
                                },
                            }) => (
                                <li key={id} className={listItem}>
                                    {icon?.sourceUrl && (
                                        <img
                                            className={listItemImg}
                                            src={`${icon?.sourceUrl}x128`}
                                        />
                                    )}
                                    <Link
                                        to={`/support/${id}`}
                                        className={listItemLink}
                                    >
                                        {title}
                                    </Link>
                                </li>
                            ),
                        )}
                    </ul>
                </div>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default Support;
