import gql from "graphql-tag";
import { Copyright } from "./_common.js";

interface MediaItemBase<T, C> {
    id: string;
    __typename: T;
    content: C;
}

export interface Thumbnail {
    fileName: string;
    width: number;
    height: number;
}

interface MediaItemContentBase {
    name: string;
    description: string | null;
    copyrights: Copyright[];
    sourceUrl: string;
}

export interface ImageContent extends MediaItemContentBase {
    thumbnails: Thumbnail[];
}

export type Image = MediaItemBase<"Image", ImageContent>;

export type ImagePreview = MediaItemBase<
    "Image",
    Pick<ImageContent, "name" | "sourceUrl" | "thumbnails">
>;

export interface YoutubeVideoContent extends MediaItemContentBase {
    thumbnailUrls: string[];
    aspectRatio: string;
    youtubeVideoId: string;
}
export type YoutubeVideo = MediaItemBase<"YoutubeVideo", YoutubeVideoContent>;

export type IMediaItem = Image | YoutubeVideo;

export type YoutubeVideoPreview = MediaItemBase<
    "YoutubeVideo",
    Pick<
        YoutubeVideoContent,
        "name" | "sourceUrl" | "thumbnailUrls" | "aspectRatio"
    >
>;
export type MediaItemPreview = ImagePreview | YoutubeVideoPreview;

export const thumbnail = gql`
    fragment thumbnail on MediaFile {
        fileName
        width
        height
        length
        contentType
    }
`;
export const image = gql`
    fragment image on Image {
        id
        content {
            name
            sourceUrl
            thumbnails {
                ...thumbnail
            }
            description
            copyrights {
                author
                url
            }
        }
    }
    ${thumbnail}
`;
